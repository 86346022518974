.image {
    width: var(--spacing-8);
}

.content {
    position: relative;
}

.link {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: var(--spacing-3);

    @media --md {
        text-align: left;
        flex-direction: row;
        justify-content: flex-start;
        gap: var(--spacing-4);
    }
}

.grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--spacing-6);

    @media --md {
        gap: var(--spacing-5);
    }
}

.grid .item {
    flex: '0 0 calc(50%)';
    @mixin breakpoint-min 600px {
        flex: 0 0 33.3%;
    }
    @media --md {
        flex: 0 0 25%;
    }
}

.grid .item .four {
    flex: 0 0 calc(50%);
    @media --md {
        flex: 0 0 25%;
    }
}

.item {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    gap: var(--spacing-3);

    @media --md {
        text-align: left;
        gap: var(--spacing-4);
        flex-direction: column;
        justify-content: space-between;
    }
}

.item img {
    display: block;
    max-width: 100%;
    height: auto;
}
